import { getApi } from 'lib/http';
import { Cookie } from 'ng2-cookies';
import { Cookies } from 'app/globals/constants';
import { generateRequestHeaders } from './utils';

const analyticsRoute = '/v1/Analytics';
const url = `${apiConfig.analyticsAPI}${analyticsRoute}`;
const api = getApi(apiConfig.analyticsAPI);

async function postFetchEventAsync(event: BaseEventJSON, user?: UserJSON) {
 await api.post(analyticsRoute, JSON.stringify(event));
}

export async function publishEvent(
 eventType: EventType,
 navCarrierData: NavCarrierData,
 userId: string,
 userEmail: string,
 eventData: SearchSubmitted | LoadSelected | BookAction | OfferAction,
 user?: UserJSON
) {
 const baseEvent: BaseEventJSON = {
  eventType: eventType,
  userId: userId,
  userEmail: userEmail,
  visitorId: Cookie.get(Cookies.visitorId),
  route: window.location.pathname,
  createdDateTimeUTC: new Date().toJSON(),
  sourceApplication: 'NavCarrierWeb',
  appVersion: appConstants.buildNumber,
  navCarrierData: navCarrierData,
  eventData: eventData,
 };

 try {
  if (eventType === 'Login') {
   await postFetchEventAsync(baseEvent, user);
  } else {
   await api.post(analyticsRoute, baseEvent, { responseType: 'json' });
  }
 } catch (e) {
  //intentinally swallow the error as we don't want to break the app if the event fails to publish
 }
}

export default { publishEvent };
